/**
 * @generated SignedSource<<d8068a9bf5bd311a71bcb596f545b853>>
 * @relayHash 1ae8953fbae5260c26a38a77cd21fdbd
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-misc/497.0.0-2025-02-10T13:07:22.933Z/ItemHeartWrapperRefetchQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemHeartWrapperRefetchQuery$variables = {
  itemId: string;
  loadPortfolioData: boolean;
  userId: string;
};
export type ItemHeartWrapperRefetchQuery$data = {
  readonly viewer: {
    readonly item: {
      readonly " $fragmentSpreads": FragmentRefs<"ItemHeartWrapper_item">;
    } | null;
  };
};
export type ItemHeartWrapperRefetchQuery = {
  response: ItemHeartWrapperRefetchQuery$data;
  variables: ItemHeartWrapperRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "loadPortfolioData"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "itemId",
    "variableName": "itemId"
  },
  {
    "kind": "Literal",
    "name": "pageType",
    "value": "PORTFOLIO"
  }
],
v4 = {
  "kind": "Variable",
  "name": "userId",
  "variableName": "userId"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ItemHeartWrapperRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Item",
            "kind": "LinkedField",
            "name": "item",
            "plural": false,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "loadPortfolioData",
                    "variableName": "loadPortfolioData"
                  },
                  (v4/*: any*/)
                ],
                "kind": "FragmentSpread",
                "name": "ItemHeartWrapper_item"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ItemHeartWrapperRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Item",
            "kind": "LinkedField",
            "name": "item",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serviceId",
                "storageKey": null
              },
              {
                "condition": "loadPortfolioData",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": "heartPortfolioItems",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "portfolioType",
                        "value": "HEART"
                      },
                      (v4/*: any*/)
                    ],
                    "concreteType": "PortfolioItemV2",
                    "kind": "LinkedField",
                    "name": "portfolioItems",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "portfolioId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "portfolioItemId",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ]
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-misc/497.0.0-2025-02-10T13:07:22.933Z/ItemHeartWrapperRefetchQuery",
    "metadata": {},
    "name": "ItemHeartWrapperRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "9f71eeff54648ab89b0d51f07c7435fd";

export default node;
